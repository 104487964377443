import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import edafacelogo from "../Assets/edaface-logo1.png";
import Headermobile from "./headermobile";

export default function Header() {

    const[headermobile, setHeadermobile] = useState(false)

    return (

        <>
        {headermobile && <Headermobile onDismiss={() => setHeadermobile(false)} />}
            <div>

                <header className="header py-2 sticky-bar">
                    <div className="container">
                        <div className="main-header">
                            <div className="header-left">
                                <div className="header-logo">
                                    <Link className="d-flex" to="/"><img src={edafacelogo} className="logos" alt="logos" /></Link>
                                </div>

                                <div className="header-nav">

                                    <nav className="nav-main-menu d-none d-xl-block">
                                        <div className="ruby-menu-demo-header">
                                            <div className="ruby-wrapper">
                                                <ul className="ruby-menu">
                                                    <li>
                                                        <Link to="https://home.edaface.com/cryptocurrencies">Cryptocurrencies</Link>
                                                        <ul className="">
                                                            <li><Link to="https://home.edaface.com/classification">Classification</Link></li>
                                                            <li><Link to="https://home.edaface.com/trending">Trending</Link></li>
                                                            <li><Link target="_blank" to="https://launchpad.edaface.com/login">EDA Token</Link></li>
                                                            <li><Link target="_blank" to="https://p2pmarket.edaface.com/">P2P Trading</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>

                                                    <li>
                                                        <Link to="https://home.edaface.com/exchanges">Exchanges</Link>
                                                        <ul className="">
                                                            <li><Link to="https://home.edaface.com/spot">Spot</Link></li>
                                                            <li><Link to="https://home.edaface.com/derivatives">Derivatives</Link></li>
                                                            <li><Link to="https://home.edaface.com/dex">Dex</Link></li>
                                                            <li><Link to="https://home.edaface.com/eda-plantation">Eda Plantation</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>

                                                    <li>
                                                        <Link to="https://home.edaface.com/verification-centre">Verification Centre</Link>
                                                        <ul className="">
                                                            <li><Link to="https://home.edaface.com/rug-pull-check">Rug Pull Check</Link></li>
                                                            <li><Link to="https://home.edaface.com/blockchain-ecosystem">Blockchain Ecosystem</Link></li>
                                                            <li><Link target="_blank" to="https://launchpad.edaface.com/">Launchpad</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>

                                                    <li>
                                                        <Link to="https://home.edaface.com/market-places">Market Places</Link>
                                                        <ul className="">
                                                            <li><Link target="_blank" to="https://nft.edaface.com/">NFT Marketplace</Link></li>
                                                            <li><Link to="https://home.edaface.com/digital-literature">Digital Literature</Link></li>
                                                            <li><Link target="_blank" to="https://mall.edaface.com/">Digital Mall</Link></li>
                                                            <li><Link to="https://home.edaface.com/metaverse">Metaverse</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>

                                                    <li className="ruby-menu-mega">
                                                        <Link to="#">EDA Academy</Link>
                                                        <ul className="">
                                                            <li><Link target="_blank" to="https://school.edaface.com/">School of Cryptocurrencies</Link></li>
                                                            <li><Link target="_blank" to="https://news.edaface.com/">Cryto News</Link></li>
                                                            <li><Link target="_blank" to="https://p2pmarket.edaface.com/">Digital Clinic</Link></li>
                                                        </ul>
                                                        <span className="ruby-dropdown-toggle"></span>
                                                    </li>

                                                    <li>
                                                        <Link to="https://home.edaface.com/resources">Resources</Link>
                                                        <ul>
                                                        <li class="ruby-open-to-left">
                                                            <Link to="https://home.edaface.com/about">About Us</Link>
                                                            <ul>
                                                                <li><Link to="https://home.edaface.com/vision">Vision</Link></li>
                                                                <li><Link to="https://home.edaface.com/mission">Mission</Link></li>
                                                                <li><Link to="https://home.edaface.com/core-values">Core Values</Link></li>
                                                                <li><Link to="https://home.edaface.com/branding">Branding</Link></li>
                                                            </ul>
                                                            <span class="ruby-dropdown-toggle"></span>
                                                        </li>
                                                        <li><Link to="https://home.edaface.com/litepaper">Litepaper</Link></li>
                                                        <li class="ruby-open-to-left">
                                                           <Link to="https://home.edaface.com/how-to-use-edaface">How to use Edaface</Link>
                                                         <ul>
                                                            <li><Link to="https://home.edaface.com/introduction">Introduction</Link></li>
                                                            <li class="ruby-open-to-left">
                                                                <Link to="https://home.edaface.com/components">
                                                                    <i className="fa fa-university" aria-hidden="true"></i>
                                                                    Components</Link>
                                                                <ul>
                                                                    <li><Link to="https://home.edaface.com/component-1">Component 1</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-2">Component 2</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-3">Component 3</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-4">Component 4</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-5">Component 5</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-6">Component 6</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-7">Component 7</Link></li> 
                                                                    <li><Link to="https://home.edaface.com/component-8">Component 8</Link></li>
                                                                    <li><Link to="https://home.edaface.com/component-9">Component 9</Link></li>
                                                                    </ul>
                                                                    <span class="ruby-dropdown-toggle"></span>
                                                            </li>
                                                            <li><Link to="https://home.edaface.com/4-characteristics-of-edaface">4 Characteristics of Edaface</Link></li>
                                                            <li><Link to="https://home.edaface.com/edaface-structure">Edaface Structure</Link></li>
                                                            </ul>
                                                            <span class="ruby-dropdown-toggle"></span>
                                                        </li>
                                                        <li class="ruby-open-to-left">
                                                           <Link to="https://home.edaface.com/whitepaper">Whitepaper</Link>
                                                         <ul>
                                                            <li><Link to="https://home.edaface.com/tokenamics">Tokenamics</Link></li>
                                                            <li><Link to="https://home.edaface.com/utility-cases">Utility Cases</Link></li>
                                                            <li><Link to="https://home.edaface.com/token-characteristics">Token Characteristicss</Link></li>
                                                            <li><Link to="https://home.edaface.com/stakability">Stakability</Link></li>
                                                            <li><Link to="https://home.edaface.com/ico-details">ICO Details</Link></li>
                                                            <li><Link to="https://home.edaface.com/airdrops-bonuses">Airdrops/Bonuses</Link></li>
                                                            <li><Link to="https://home.edaface.com/road-map">Road Map</Link></li>
                                                            <li><Link to="https://home.edaface.com/disclaimer">Disclaimer</Link></li>
                                                            </ul>
                                                            <span class="ruby-dropdown-toggle"></span>
                                                        </li>
                                                        <li class="ruby-open-to-left">
                                                           <Link to="https://home.edaface.com/teams-1">Team</Link>
                                                         <ul>
                                                            <li><Link to="https://home.edaface.com/Departments">Departments</Link></li>
                                                            <li><Link to="https://home.edaface.com/board-of-founders">Board of Founders</Link></li>
                                                            <li><Link to="https://home.edaface.com/board-of-advisors">Board of Advisors</Link></li>
                                                            <li><Link to="https://home.edaface.com/partners">Partners</Link></li>
                                                            </ul>
                                                            <span class="ruby-dropdown-toggle"></span>
                                                        </li>
                                                        <li class="ruby-open-to-left">
                                                           <Link to="https://home.edaface.com/faq">FAQ</Link>
                                                         <ul>
                                                            <li><Link to="https://home.edaface.com/faq-on-eda">FAQ on EDA</Link></li>
                                                            <li><Link to="https://home.edaface.com/faq-on-edaface">FAQ on EdaFace</Link></li>
                                                            <li><Link to="https://home.edaface.com/faq-on-eda-token">FAQ on Eda Token</Link></li>
                                                           
                                                            </ul>
                                                            <span class="ruby-dropdown-toggle"></span>
                                                        </li>
                                                        </ul>
                                                    </li>



                                                </ul>
                                            </div>
                                        </div>
                                    </nav>

                                    <div class="burger-icon burger-icon-white"  onClick={() => { setHeadermobile(true) }}><span class="burger-icon-top"></span><span class="burger-icon-mid"></span><span class="burger-icon-bottom"></span></div>

                                </div>

                                <div class="header-right">
                                    <div class="d-none d-sm-inline-block">
                                        <Link class="btn btn-brand-1 hover-up" to="/">Get Started</Link>
                                        </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>

            </div>


        </>
    );
}
