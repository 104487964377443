import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa6";
import instagram from "../Assets/instagram.png";
import twitter from "../Assets/x.png";
import telegram from "../Assets/telegram.png";
import youtube from "../Assets/youtube.png";
import facebook from "../Assets/facebook.png";
import edafacelogo from "../Assets/edaface-logo1.png"

export default function Footer() {

    return (

        <>


            <footer class="footer">
                <div class="footer-1">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-3 width-20">
                                <Link to="#"><div class="mb-10">
                                    <img src={edafacelogo} alt="EdaFace" />
                                </div>
                                </Link>
                                <h6 class="color-brand-1">Follow Us</h6>
                                <div class="mt-15">
                                    <Link class="icon-socials" target="_blank" to="https://www.instagram.com/edaface_office/" title="Instagram">
                                        <img src={instagram} alt="Instagram" />
                                    </Link>
                                    <Link class="icon-socials" target="_blank" to="https://twitter.com/EdaFace_office" title="Twitter">
                                        <img src={twitter} alt="Twitter" />
                                    </Link>
                                    <Link class="icon-socials" target="_blank" to="https://www.facebook.com/EdaFace.Office1" title="Facebook">
                                        <img src={facebook} alt="Facebook" /></Link>
                                    <Link class="icon-socials" target="_blank" to="https://t.me/+8O9tfHSRdEZkY2E0" title="Telegram">
                                        <img src={telegram} alt="Telegram" /></Link>
                                    <Link class="icon-socials" target="_blank" to="https://www.youtube.com/channel/UCpCIoI_03jXv7rC7DUdCAww" title="YouTube">
                                        <img src={youtube} alt="YouTube" /></Link>
                                </div></div><div class="col-lg-3 width-16 mb-30">

                                <h5 class="mb-10 color-brand-1">Discover EdaFace</h5>

                                <ul class="menu-footer">
                                    <li><Link to="https://home.edaface.com/cryptocurrencies" title="Cryptocurrencies">Cryptocurrencies</Link></li>
                                    <li><Link to="https://home.edaface.com/exchanges" title="Exchanges">Exchanges</Link></li>
                                    <li><Link to="https://home.edaface.com/verification-centre" title="Verification Centre">Verification Centre</Link></li>
                                    <li><Link to="https://home.edaface.com/market-places" title="Market Places">Market Places</Link></li>
                                    <li><Link to="https://home.edaface.com/eda-academy" title="EDA Academy">EDA Academy</Link></li>
                                </ul>
                                </div>
                                
                                <div class="col-lg-3 width-16 mb-30">
                                    <h5 class="mb-10 color-brand-1">Resources</h5>
                                    <ul class="menu-footer">
                                        <li><Link to="https://home.edaface.com/about" title="About Us">About Us</Link></li>
                                        <li><Link to="https://home.edaface.com/litepaper" title="LitePaper">LitePaper</Link></li>
                                        <li><Link to="https://home.edaface.com/whitepaper" title="Whitepaper">Whitepaper</Link></li>
                                        <li><Link to="https://home.edaface.com/how-to-use-edaface" title="How to use EdaFace">How to use EdaFace</Link></li>
                                        <li><Link to="https://home.edaface.com/faq" title="FAQ">FAQ</Link></li>
                                    </ul>
                                    </div>
                                    
                                    <div class="col-lg-3 width-16 mb-30">
                                        <h5 class="mb-10 color-brand-1">Legal</h5>
                                        <ul class="menu-footer">
                                            <li><Link to="https://home.edaface.com/term-and-conditions" title="Term of Use">Term of Use</Link></li>
                                            <li><Link to="https://home.edaface.com/privacy-policy" title="Privacy Policy">Privacy Policy</Link></li>
                                            <li><Link to="https://home.edaface.com/disclaimer" title="Disclaimer">Disclaimer</Link></li>
                                            <li><Link to="https://home.edaface.com/Eda Token Policy" title="Eda Token Policy">Eda Token Policy</Link></li>
                                        </ul>
                                    </div>
                                    
                                    <div class="col-lg-3 width-16 mb-30">
                                        <h5 class="mb-10 color-brand-1">Contact Us</h5>
                                        <ul class="menu-footer">
                                            <li><Link to="https://home.edaface.com" title="+27732193350">+27732193350</Link></li>
                                            <li><Link to="https://home.edaface.com" title="support@edaface.com">support@edaface.com</Link></li>
                                            <li><Link to="https://home.edaface.com/contact" title="Contact">Contact</Link></li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    </div>

                                    <div class="footer-custom">
                                        <div class="container">
                                            <div class="footer-bottom">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-12 text-center">
                                                        <span class="color-grey-300 font-md">Copyright © <span id="copyright-year">2023</span>. EdaFace is a product of Emerging Digital Age (EDA) Pty Ltd. All Rights Reserved.</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                     
                                     </div>


                                     <a id="scrollUp" href="#top"><FaArrowUp /></a>

                         </footer>




        </>
    );
}
