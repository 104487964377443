import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./Pages/register";
import Login from "./Pages/login";
import Forgot from "./Pages/forgot";
import './App.css';


export default function App() {

  return (
    <>

      <BrowserRouter>    
        <Routes>
        <Route path="/" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        </Routes>
        </BrowserRouter>

</>
);
}

