import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { FaArrowRight } from "react-icons/fa6";
import logocircle from "../Assets/logo-circle2.png";
import newsletter from "../Assets/newsletter-image3.png";

export default function Login() {

    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, []);

    return (

        <>

            <div className="logindetail">

            <Header /> 

            <section class="section">
                <div class="container font-md">
                    <section class="section banner-login position-relative float-start">
                     <div className="box-banner-abs">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xxl-5 col-xl-12 col-lg-12">
                                    <div className="box-banner-login">
                                        <h2 className="color-brand-1 mb-3">Welcome back</h2>
                                        <p className="font-md color-grey-500 mb-4">Fill your email address and password to sign in.</p>

                                        <form method="POST" class="row" action="" novalidate="novalidate">

                                        <div class="col-lg-12">
                                                <div class="form-group mb-4">
                                                    <input class="form-control icon-user " name="email" type="email" placeholder="Your email"/>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-12">
                                                <div class="form-group mb-4">
                                                    <input class="form-control icon-password " name="password" type="password" placeholder="Password"/>
                                                  </div>
                                             </div>
                                                  
                                            <div class="col-lg-6 col-6 mt-15">
                                                <div class="form-group mb-4">
                                                    <label class="cb-container">
                                                    <input type="checkbox" className="form-check-input" checked/>
                                                            <span class="text-small ms-2">Remember me</span><span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-6 col-6 mt-15">
                                                <div class="form-group mb-4 text-end">
                                                    <Link class="font-xs color-grey-500"  to="/forgot">Forgot password?</Link>
                                                    </div>
                                            </div>
                                            
                                            <div class="col-lg-12 mb-4">
                                            <Link  to="https://home.edaface.com/"><button class="btn btn-brand-lg btn-full font-md-bold" type="button">Sign in</button></Link>
                                            </div>
                                            
                                            </form>

                                            <div class="col-lg-12">
                                                <span class="color-grey-500 d-inline-block align-middle font-sm"> Don’t have an account? </span>
                                                <Link class="d-inline-block align-middle color-success ms-1" to="/">Sign up now</Link>
                                             </div>
                                  
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
            
            </section>
            </div>
            </section>


            <div className="box-newsletter">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 mt-3 col-md-12">
                                <div className="box-image-newsletter">
                                    <div className="">
                                        <img className="img-main" src={newsletter} alt="Image NewsLetter" />
                                        <div className="shape-2 image-1"><img src={logocircle} alt="Icon primary"/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mt-3">
                                <span className="font-lg color-brand-1">Newsletter</span>
                                <h2 className="color-brand-1 mt-2 mb-15">Subscribe our newsletter</h2>
                                <p className="font-md color-grey-500">By clicking the button, you are agreeing with our Term & Conditions</p>
                                <div className="form-newsletter mt-3 position-relative">
                                <form action="" className="newsletter-form" novalidate="novalidate">                                  
                                        <input type="email" name="email" placeholder="Enter your email..."/>
                                            <button className="btn btn-submit-newsletter" type="submit">
                                                {/* <svg id="btn-arrow" className="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                                <div id="loading" role="status" className="page_speed_1987120936 spinner-border text-white loading-newsletter"></div> */}
                                             <FaArrowRight/>
                                                </button>
                                 </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            <Footer />

            </div>

        </>
    );
}
