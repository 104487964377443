import React, { useEffect, useState } from "react";
import { Button, Container, Row } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import Header from "../Layout/header";
import Footer from "../Layout/footer";
import { FaArrowRight } from "react-icons/fa6";
import logocircle from "../Assets/logo-circle2.png";
import newsletter from "../Assets/newsletter-image3.png";

export default function Register() {
    const EMAILregex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const[forms,setForms] = useState({});
    const [focusoutfield,SetFocusOutField] = useState({});


    useEffect(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }, []);

    return (

        <>

            <div className="register">

                <Header />

                <section className="section">
                    <div className="container font-md">
                    <section className="section box-page-register">
                    <div className="container">
                        <div className="row">
                            
                          <div className="col-lg-5">
                            <div className="box-steps-small">

                                <div className="item-number hover-up">
                                <div className="num-ele">1</div>
                                <div className="info-num"><h5 className="color-brand-1 mb-15">Register</h5><p className="font-md color-grey-500">All you need is your name, email and a strong password, Or use your social media accounts.</p></div>
                                </div>

                                <div className="item-number hover-up">
                                <div className="num-ele">2</div>
                                <div className="info-num"><h5 className="color-brand-1 mb-15">Activate</h5><p className="font-md color-grey-500">Use the code sent to your email to activate your account.</p></div>
                                </div>

                            </div>
                            </div>

                            <div className="col-lg-7">
                         <div className="box-register">
                         <h2 className="color-brand-1 mb-15">Create an account</h2>
                         <p className="font-md color-grey-500 mb-4">Create an account today and start using our platform</p>
                         
                            <form  className="row" action="" novalidate="novalidate">

                                <div className="col-lg-12 col-sm-12">
                                    <div className="form-group mb-4">
                                        <input required="" className={ focusoutfield?.name ? (forms?.name?.length > 2 ? "form-control icon-name is-valid" : "form-control icon-name is-invalid") : "form-control icon-name"} type="text" name="name" placeholder="Your name" onChange={(e)=>setForms({...forms,...{[e.target.name]:e.target.value}})} onBlur={(e)=>{console.log('fojdjfdgdg',e.target.name);SetFocusOutField({ ...focusoutfield, ...{[e?.target?.name] : true}})}} />
                                  </div>
                                </div>

                                <div className="col-lg-12 col-sm-12">
                                    <div className="form-group mb-4"> 
                                        <input required="" className="form-control icon-email " type="email" name="email" placeholder="Your email" onChange={(e)=>setForms({...forms,...{[e.target.name]:e.target.value}})} onBlur={(e)=>SetFocusOutField(e?.target?.name)}/>
                                            </div>
                                </div>
                                                                               
                                <div className="col-lg-12 col-sm-12">
                                        <div className="form-group mb-4">
                                         <input required="" className="form-control icon-password input-green-bd " type="password" name="password" autocomplete="new-password" placeholder="Password" aria-required="true" onChange={(e)=>setForms({...forms,...{[e.target.name]:e.target.value}})} onBlur={(e)=>SetFocusOutField(e?.target?.name)}/>
                                   </div>
                                </div>
                                   
                                <div className="col-lg-12 col-sm-12">
                                    <div className="form-group mb-4">
                                    <input required="" className="form-control icon-password input-green-bd " type="password" name="password_confirmation" placeholder="Password confirmation" aria-required="true" onChange={(e)=>setForms({...forms,...{[e.target.name]:e.target.value}})} onBlur={(e)=>SetFocusOutField(e?.target?.name)}/>
                                    </div>
                                </div>
                                                                    
                                <div className="col-lg-12 mt-15">
                                    <div className="form-group mb-4">
                                        <label><span className="text-small">Your personal data will be used to support your experience throughout this website, to manage access to your account, and for other purposes described in our privacy policy.</span></label>
                                    </div>
                                    <div className="form-group mb-4">
                                        <label className="cb-container">
                                            <input type="checkbox" className="form-check-input" />
                                            <span className="text-small ms-2">I agree to terms &amp; Policy.</span>
                                        </label>
                                    </div> 
                                </div>
                                            
                                            
                                <div className="row align-items-center mt-30 ">
                                        <div className="col-xl-5 col-lg-5 col-md-5 col-sm-6 col-6">
                                              <div className="form-group">
                                                    <Link to="https://home.edaface.com/"><button className="btn btn-brand-lg btn-full font-md-bold" type="button">Sign up now</button></Link>
                                              </div>
                                        </div>
                                                
                                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-6 col-6">
                                            <span className="d-inline-block align-middle font-sm color-grey-500">Already have an account?</span>
                                                    
                                              <Link className="d-inline-block align-middle color-success ms-1" to="/login"> Sign In</Link>
                                        </div>
                                </div>

                            </form>

                         
                        </div>
                          
                            </div>

                            </div>
                            </div>
                        </section>
                    </div>
                </section>

                <div className="box-newsletter">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 mt-3 col-md-12">
                                <div className="box-image-newsletter">
                                    <div className="">
                                        <img className="img-main" src={newsletter} alt="Image NewsLetter" />
                                        <div className="shape-2 image-1"><img src={logocircle} alt="Icon primary"/></div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 mt-3">
                                <span className="font-lg color-brand-1">Newsletter</span>
                                <h2 className="color-brand-1 mt-2 mb-15">Subscribe our newsletter</h2>
                                <p className="font-md color-grey-500">By clicking the button, you are agreeing with our Term & Conditions</p>
                                <div className="form-newsletter mt-3 position-relative">
                                <form action="" className="newsletter-form" novalidate="novalidate">
                              
                                        <input type="email" name="email" placeholder="Enter your email..."/>
                                            <button className="btn btn-submit-newsletter" type="submit">
                                                {/* <svg id="btn-arrow" className="w-6 h-6 icon-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                                <div id="loading" role="status" className="page_speed_1987120936 spinner-border text-white loading-newsletter"></div> */}
                                             <FaArrowRight/>
                                                </button>
                                 </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <Footer />

            </div>

        </>
    );
}
