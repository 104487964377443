import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, Accordion, Button, Offcanvas } from 'react-bootstrap';
import { Link, NavLink } from "react-router-dom";
import edafacelogo from "../Assets/edaface-logo1.png";
import { IoClose } from "react-icons/io5";


const Codedetail = (props) => {

    const [headermobile, setHeadermobile] = useState(true);

    return (

        <>

            <div className='viewcanvas'>
                <Offcanvas show={headermobile} placement='end' name="end">
                    <Offcanvas.Header>
                    <Link className="d-flex" to="/"><img src={edafacelogo} className="logos" alt="logos" /></Link>
                        <button className='btn close' onClick={() => props.onDismiss()}><IoClose/></button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                         <div>
                            <h4 className='fw-700'>MENU</h4>
                          <h4>Coming Soon..</h4>
                          </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </div>


        </>
    )
}

export default Codedetail;
